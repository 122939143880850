import { createAuth0Client } from "@auth0/auth0-spa-js";
import { user, contract } from "../stores/index";
import { navigate } from "svelte-routing";
import { UsersRepository } from "../repositories/index";

import getFile from "./xml";
const config = getFile("/config.json");

let auth0Client;

async function login() {
  try {
    if (!auth0Client) {
      await setAuth0Client();
    }

    const result = await auth0Client.loginWithPopup({
      authorizationParams: { max_age: 0, prompt: "login" },
    });

    const token = await auth0Client.getTokenSilently({
      detailedResponse: true,
    });

    const now = new Date();
    const expirationDate = new Date(now.getTime() + token.expires_in * 1000);

    user.set({
      token: {
        token: token.id_token,
        type: "Bearer",
        exp: expirationDate,
      },
    });

    await setUser(token);
  } catch (e) {
    user.set(null);
    contract.set(null);
    throw e;
  }
}

async function setAuth0Client() {
  try {
    auth0Client = await createAuth0Client({
      domain: config.AUTH0_DOMAIN,
      clientId: config.AUTH0_CLIENT_ID,
      useRefreshTokens: true,
      authorizationParams: {
        audience: config.AUTH0_AUDIENCE,
      },
      max_age: 0,
    });
  } catch (error) {
    throw error;
  }
}

async function setUser() {
  try {
    const result = await UsersRepository.me();

    let userStore;
    user.subscribe((data) => {
      userStore = data;
    });

    user.set({
      data: result.data,
      contracts: result.contracts,
      ...userStore,
    });
    redirectUser();
  } catch (error) {
    throw error;
  }
}

async function redirectUser() {
  let userStore;
  user.subscribe((data) => {
    userStore = data;
  });

  let contractStore;
  contract.subscribe((data) => {
    contractStore = data;
  });

  if (userStore.contracts.length === 0 && !userStore.data.isMaster) {
    throw new Error({
      message: "This user is not assigned to any application",
    });
  } else if (userStore.contracts.length > 1 || userStore.data.isMaster) {
    contract.set(null);
    navigate("/contracts");
  } else {
    contract.set(userStore.contracts[0]);

    try {
      const contractApps = await UsersRepository.getUserApps(
        userStore.data.id,
        userStore.contracts[0]
      );
      const policies = await UsersRepository.getUserPolicies(userStore.data.id);
      user.set({
        ...userStore,
        apps: contractApps.list,
        policies: policies.list,
      });

      navigate(`/contracts/${contractStore.id}`);
    } catch (error) {
      throw error;
    }
  }
}

async function logout() {
  if (!auth0Client) {
    await setAuth0Client();
  }

  try {
    await auth0Client.logout({
      logoutParams: {
        federated: true,
      },
    });
    user.set(null);
    contract.set(null);
    navigate("/");
  } catch (error) {
    console.log("error", error);
  }
}

const auth0 = {
  login,
  logout,
};

export default auth0;
