<script>
  import { contract, user, _ } from "../stores/index";
  import {
    Select,
    SelectItem,
    Button,
    GL,
    Flex,
    FlexItem,
  } from "@gl/design-system";
  import { navigate } from "svelte-routing";
  import { UsersRepository } from "../repositories/index";
  import { onMount, getContext } from "svelte";

  let selectedContract;
  let loading;

  const userContracts = $user.contracts;

  async function select() {
    contract.set(selectedContract);
    try {
      const policies = await UsersRepository.getUserPolicies(
        $user.data.id,
        selectedContract.id
      );

      const apps = await UsersRepository.getUserApps($user.data.id);

      user.set({ ...$user, policies: policies.list, apps: apps.list });
    } finally {
      navigate(`/contracts/${$contract.id}`);
    }
  }

  onMount(() => {
    if (!$user.contracts) {
      navigate("/");
    }
  });
</script>

<div class="cubes-bg" id="contracts-page">
  <Flex class="flex-direction-column align-items-center justify-content-center">
    <FlexItem class="mb-2">
      <GL light />
    </FlexItem>
    <FlexItem>
      <h1 class="title">{getContext("config").NAME}</h1>
    </FlexItem>
  </Flex>

  {#if userContracts}
    <div class="form mt-5">
      <p class="sub-title">{$_("contract.select")}</p>
      <p class="contract-info">{$_("contract.all")}</p>
      <div class="select">
        <Select
          label=" "
          bind:value={selectedContract}
          {loading}
          style="width:100%"
        >
          {#each userContracts as contract}
            <SelectItem value={contract} label={contract.name} />
          {/each}
        </Select>
        <Button on:click={select} style="width:399px">
          {$_("common.select")}
        </Button>
      </div>
    </div>
  {/if}
</div>

<style>
  .form {
    width: 399px;
    margin: 0 auto;
  }
  .select :global(.gl-select) {
    width: 399px;
  }
</style>
